import styled from 'styled-components'
import { breakpoints } from 'src/styles/breakpoints'

export const Card = styled.div`
  width: 280px;
  height: 280px;
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 8px;
  position: relative;

  @media (min-width: ${breakpoints.xl}){
    width: 270px;
    height: 270px;
  }

  .text {
    position: absolute;
    bottom: 17px;
  }
`

export const CardImage = styled.div`
  float: left;
`

export const Content = styled.div`
  margin: 0 auto;
  width: 890px;
`
