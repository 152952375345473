import React, { useEffect, useRef } from 'react'
import * as S from './style'
import YourCompleteJourneyJSON from '../../../assets/data/your-complete-journey.json'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

type YourCompleteJourneyProps = {
  title: string;
  description: string;
  image: string;
  link: string;
}

const Mobile = () => {
  const content01Ref = useRef<HTMLDivElement>(null)
  const [ sendDatalayerEvent ] = useDataLayer()

  const handleDataLayer = (title: string, url: string) => {
    sendDatalayerEvent({
      section: 'section_06',
      section_name: 'Sua viagem completa',
      element_action: 'click button',
      element_name: title,
      redirect_url: url,
    })
  }

  useEffect(() => {
    if (content01Ref.current) {
      const containerWidth = content01Ref.current.scrollWidth
      const viewportWidth = content01Ref.current.clientWidth
      content01Ref.current.scrollLeft = (containerWidth - viewportWidth) / 2
    }
  }, [])

  return (
    <>
      <div ref={content01Ref} style={{ overflowX: 'auto' }}>
        <div className='d-flex'>
          {YourCompleteJourneyJSON.slice(0, 3).map((item: YourCompleteJourneyProps) => (
            <a
              key={item.title}
              href={item.link}
              onClick={() => handleDataLayer(item.title, item.link)}
            >
              <S.CardImage className='mr-3'>
                <S.Card style={{ backgroundImage: `url(${item.image})` }} className='mb-3 px-4'>
                  <div className='text pr-3 pr-lg-4'>
                    <h2 className='fs-20 lh-25 text-white'>{item.title}</h2>
                    <p className='fs-16 lh-19 text-white mb-0' dangerouslySetInnerHTML={{ __html: item.description }} />
                  </div>
                </S.Card>
              </S.CardImage>
            </a>
          ))}
        </div>
        <div className='d-flex'>
          {YourCompleteJourneyJSON.slice(3, 6).map((item: YourCompleteJourneyProps) => (
            <a
              key={item.title}
              href={item.link}
              onClick={() => handleDataLayer(item.title, item.link)}
            >
              <S.CardImage className='mr-3'>
                <S.Card style={{ backgroundImage: `url(${item.image})` }} className='mb-3 px-4'>
                  <div className='text pr-3 pr-lg-4'>
                    <h2 className='fs-20 lh-25 text-white'>{item.title}</h2>
                    <p className='fs-16 lh-19 text-white mb-0' dangerouslySetInnerHTML={{ __html: item.description }} />
                  </div>
                </S.Card>
              </S.CardImage>
            </a>
          ))}
        </div>
        <S.Content className='d-flex justify-content-center'>
          {YourCompleteJourneyJSON.slice(6, 7).map((item: YourCompleteJourneyProps) => (
            <a
              key={item.title}
              href={item.link}
              onClick={() => handleDataLayer(item.title, item.link)}
            >
              <S.CardImage className='mr-3'>
                <S.Card style={{ backgroundImage: `url(${item.image})` }} className='mb-3 px-4'>
                  <div className='text pr-3 pr-lg-4'>
                    <h2 className='fs-20 lh-25 text-white'>{item.title}</h2>
                    <p className='fs-16 lh-19 text-white mb-0' dangerouslySetInnerHTML={{ __html: item.description }} />
                  </div>
                </S.Card>
              </S.CardImage>
            </a>
          ))}
        </S.Content>
      </div>
    </>
  )
}

export default Mobile
